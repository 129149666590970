import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
//import Page from './views/Page.vue';

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      // beforeEnter: () => {
      //   if( localStorage.getItem( "device-token" )) {
      //     console.log( "redirecting Home route");
      //     next( "/page" );
      //   }
      // },
      component: Home,
      meta: {
        guest: true
      }
    },
    {
      path: '/about',
      name: 'about',
      meta: { 
        requiresAuth: true,
        // is_admin : true
      },
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    },
    {
      path : '/page/:id',
      name : 'Page',
      props: true,
      meta : { 
        requiresAuth: true,
      },
      component: () => import(/* webpackChunkName: "page" */ './views/Page.vue')
    },
    {
      path: '/activate',
      name: 'activate',
      meta: {
        guest:true
      },
      component: () => import(/* webpackChunkName: "Activate" */ './views/Activate.vue')
    },
    {
      path: '*',
      meta: {
        guest:true
      },
      component: () => import(/* webpackChunkName: "login" */ './views/404.vue')
    }
  ]
})


router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {

    let t = this;
      if (localStorage.getItem('device-token') === null) {
          next({
              path: '/',
              params: { nextUrl: to.fullPath }
          })
      } else {
        return next();
      }
  } else if(to.matched.some(record => record.meta.guest)) {

    if( to.path == '/activate' ) {
      return next();
    }
    
    if(localStorage.getItem('device-token') == null){
        next()
    } else {
        next({path:'/page/default'})
      }
  } else {
    console.log( "route neither guest or auth required");
      next() 
  }
})

export default router;