<template>
  <div class="card home shadow show-hand" style="display:inline-block;padding:2em;margin-top:10em;width:50%;vertical-align:middle;text-align:center;border-radius:3em;" @click="logout">
    <div>
      <img alt="Display Dog Logo" style="width:100%;" src="@/assets/img/displaydog-logo-500.png" />
    </div>
    <p>
      Welcome to DISPLAY Dog
    </p>
    <button class='btn btn-sm btn-primary'>Activate</button>
    <p style="margin-top:2em;font-size:.85em;font-style:italic;">
      Pressing Activate will send your system settings to our servers and begin the activation process
    </p>
  </div>

</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import GeneralService from "@/services/GeneralService.js";

export default {
  name: 'home',
  components: {
  },
  data() {
    return {
      // isDefault    : true,       // if we don't have a page yet...
      // isPlaceHolder: false,
      // width        : "1920px",
      // height       : "1080px"
    }
  },
  created() {

    // this.isDefault = !this.$root.isLoggedIn();
    // this.$root.schedule = JSON.parse(localStorage.getItem( 'schedule' ));

    // if( !this.isDefault ) {
    //   this.isPlaceHolder = true;
    // }

    // console.log( "home.vue created() device=", this.$root.device );

  },
  async mounted() {
    // console.log("home.vue mounted()")
    // this.getDeviceSchedule();
  },
  methods: {
    // async getDeviceSchedule() {

    //   try {
    //     let response = await GeneralService.getDeviceSchedule( this.$root.siteInfo._id );

    //     this.isDefault = false;
    //     this.isPlaceHolder = true;

    //     localStorage.setItem( 'schedule' , JSON.stringify( response.data ));
    //     this.$root.schedule = response.data;

    //   } catch( error ) {
    //     console.log( error.message );
    //   }

    // },
    logout() {
      this.$root.deactivate();
    }
  }
}
</script>
<style language="scss" scoped>

  .kiosk-page {
    background:black;
  }

</style>