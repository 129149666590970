/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated( registration ) {
      console.log("A new app version is available", registration )
      
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
      
      //alert("A new version of mySchool is available. To run the new version all current instances of this site need to be closed.\n\n1) Press OK\n2) Close this tab\n3) Launch mySchool again.\n\nYou do NOT need to restart your computer.")
      //window.reload();
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
