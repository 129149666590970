import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

import App from './App.vue'
import router from './router'
import VueAnalytics from 'vue-analytics';
import './registerServiceWorker'
import PortalVue from 'portal-vue'
import axios from "axios";
import _ from "lodash";
import Api from "@/services/Api";

import 'bootstrap/dist/css/bootstrap.css' // https://bootstrap-vue.js.org/docs/
import 'bootstrap-vue/dist/bootstrap-vue.css' // when using custom css, refer to the above docs.
import "@/assets/css/widgets.css";

const {
  version
} = require('../package.json');

Object.defineProperty(Vue.prototype, '$_', { value: _ });

Vue.use(BootstrapVue)
Vue.use(PortalVue)

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id :"UA-9964551-15", // Old - "UA-9964551-34", 
  router,
  autoTracking: {
    pageviewTemplate(route) {
      let o = {};
      if(typeof route.matched != 'undefined') {       // wjs: 03-Nov-17 :: Figure if matched is present, it's an array with at least 1 element
        o.title = route.matched[ 0 ].name;            //   seems to be the same as route.name, but for consistency used the matched name
        if(route.matched[ 0 ].path != '*') {          // let's capture the 404's so this is the "catch-all" we will replace.
          o.path     = route.matched[ 0 ].path;
          o.location = route.matched[ 0 ].path;       // The matched route, which is the raw route definition, as our location
        } else {
          o.path     = route.path;                    // we hit *, which would mean a 404, so use the original url so we capture it.
          o.location = window.location.href;
        }
      } else {
        o.title    = route.name;
        o.path     = route.path;
        o.location = window.location.href;
      }
      return o;
    }
  }
})

new Vue({
  router,
  render: h => h(App),
  data: {
    version         : version,
    TZ              : "America/Chicago",
    alias           : "",                  // me
    user            : null,
    device          : null,
    siteInfo        : null,
    accessToken     : null,
    "x-access-token": null,
    applicationKey  : null,
    schedules       : null,
    "appTitle"      : "main.js"
  },
  created() {
    this.isLoggedIn();
  },
  mounted() {
  },
  methods: {
    isLoggedIn() {

      let user = JSON.parse(localStorage.getItem('user'))
      if( user ) {
        this.getLocalToken();
        this.user     = user;
        this.alias    = user.alias;
        this.device   = JSON.parse(localStorage.getItem('device'));
        this.siteInfo = JSON.parse(localStorage.getItem('siteInfo'));
        this.TZ       = this.siteInfo.TZ;

        console.log( "Setting TZ for", this.TZ) ;
        
        return true;
      }
      return false;
    },
    userAlias() {
      return this.alias || "Undefined User";
    },
    tz() {
      return this.siteInfo.TZ;
    },
    getLocalToken() {
      let token          = localStorage.getItem('device-token');
      let applicationKey = localStorage.getItem('appkey');
      this.accessToken    = token;
      this.applicationKey = applicationKey ;
      axios.defaults.headers.common[ 'x-access-token' ] = token;
      axios.defaults.headers.common[ 'applicationkey' ] = applicationKey;
    },
    setToken(token, applicationKey) {
      this.accessToken    = token;
      this.applicationKey = applicationKey ;
      axios.defaults.headers.common[ 'x-access-token' ] = token;
      axios.defaults.headers.common[ 'applicationkey' ] = applicationKey;
      localStorage.setItem('device-token', token );
      localStorage.setItem('appkey' , applicationKey );
    },
    setDeviceConfig( device ) {
      this.device = device;
      localStorage.setItem('device' , JSON.stringify(device));
    },
    setSiteInfo( siteInfo ) {
      this.siteInfo = siteInfo;
      localStorage.setItem('siteInfo', JSON.stringify(siteInfo));
    },
    setSchedules( schedules ) {
      this.schedules = schedules;
      localStorage.setItem( 'schedules' , JSON.stringify( schedules ));
    },
    setUser( user ) {
      this.user  = user;
      this.alias = user.alias;
      localStorage.setItem('user',JSON.stringify(user));   // user only comes in on activate.
    },
    activate( device ) {

      console.log( "main.js->activate() Got" , JSON.stringify(device,null,2));

      let token = device[ "device-token" ];
      let applicationKey = device.applicationkey;

      this.setToken( token , applicationKey );
      this.setDeviceConfig( device.device.device );
      this.setUser( device.user );
      
      let siteInfo = device.device;
      delete siteInfo.device;
      console.log( "Site Info:" , siteInfo)
      this.setSiteInfo(siteInfo);
    },
    deactivate() {
      this.user = null;
      this.alias = "";
      Api.token = "";
      localStorage.clear();

      this.$router.push( "/activate");
    }
  }
}).$mount('#app')
